import { useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import * as THREE from 'three'
import { useEffect, } from "react"


import AppFunctions from "../AppFunctions.js"
export default function StreetSign(){
    const app = new AppFunctions()
    const model = useLoader(GLTFLoader, app.imports.models.streetsign, () =>{
        app.loadingManager.done()
    })

    useEffect(() =>{
        const setWorld = async() =>{
            const weather = await app.weather.getWeatherData()
            if(weather.is_day){
                model.scene.traverse((child) =>{
                    if(child.isMesh && child.name == 'lamp'){        
                        child.material = new THREE.MeshStandardMaterial({color: 'white'})   
                    }
                })
            }
        }
        setWorld()
        
    })
    
    return <primitive object={model.scene} position={[-1,-.05,-0.8]} />
}