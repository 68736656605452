import { Environment,PresentationControls, ContactShadows,Float,SpotLight} from '@react-three/drei'
import Avatar from './Avatar.jsx'
import StreetSign from './Streetsign.jsx'
import Nemo from './Nemo.jsx'
import Bag from './Bag.jsx'
import Weather from './Weather.jsx'
import AppFunctions from '../AppFunctions.js'
import { useEffect, useState } from 'react'

export default function World()
{     
    const app = new AppFunctions()
    const [eveningLight, setEveningLight] = useState(false)
    const [envPreset, setEnvPreset] = useState('dawn')

    useEffect(() =>{
        const setWorld = async() =>{
            const weather = await app.weather.getWeatherData()
            if(!weather.is_day){
                setEveningLight(true)
                setEnvPreset('sunset')
            }
        }
        setWorld()
    })
    return <>
        <PresentationControls
            global
            rotation={[0,0,0]}
            polar={[0.0,0]}
            config={{mass:2,tension: 400}}
            azimuth={[-0.8,0.8]}
            snap={{mass: 2, tension:400}}

        >   
            <StreetSign />
            <Float speed={0.5}>
                <Weather/>
            </Float>
            <Nemo />
            <Bag/>
            <Avatar />
            {eveningLight && (
            <SpotLight
                position={[-1.1,2.68,-0.8]}
                color={'#ee9f72'}
                distance={3.2}
                attenuation={2.6}
                anglePower={3} // Diffuse-cone anglePower (default: 5)
                radiusTop={0.1}
                radiusBottom={1.6}
            />
            )}
            <Environment preset={envPreset} blur={0.8} />
            <hemisphereLight groundColor="red" />
            
        </PresentationControls>
        <ContactShadows
            position={[0,-0.01,0]}
            opacity={ .5 }
            scale={8}
            blur={ 1 }
        />
    </>
}