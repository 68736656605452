import './style.css'
import AppFunctions from './AppFunctions.js'
import { track } from '@vercel/analytics';
import { Canvas } from '@react-three/fiber'
import {useEffect, useState, createContext } from 'react'
import Intro from './World/Intro.jsx'
import Overlay from './Utils/Overlay.jsx'
import World from './World/World.jsx'
import DynoBG from './World/dyno-bg.jsx';

export const projectContext = createContext()

export default function App(){
    const app = new AppFunctions()

    const [isOverlayOpen, setIsOverlayOpen] = useState(false)
    let projectDetails = {
        mainProjectID: 0,
        sideProjectID: 0,
        sideProjectBool: false
    }
    
    const [projectObject, setProjectObject] = useState(projectDetails)
    
    //Checks everytime if signal needs to be send
    useEffect(() =>{
        if(projectObject.update){
            app.iSeeYou.seeSignal(projectObject)
        }
    },[projectObject])
    
    const setOverlay = (projectID,sideProjectBool,sideProjectID)=>{
        setProjectObject({
            mainProjectID:projectID,
            sideProjectID:sideProjectID,
            sideProjectBool:sideProjectBool,
            update: true
        })
        setIsOverlayOpen(!isOverlayOpen)
        track('OpenOverlay',{ProjectId: projectID })
    }
    const closeOverlay = (method) =>{
        track('CloseOverlay',{method:method})
        setIsOverlayOpen(false)
        
    }

    const [loadingView, setLoadingView] = useState(true)
    useEffect(() =>{
        app.loadingManager.on('done', ()=>{
            setTimeout(() =>{
                setLoadingView(false)
            },750)
        })
    })

    return <>
        <projectContext.Provider value={[projectObject,setProjectObject]}>
            <Overlay trigger={isOverlayOpen} closeOverlay={closeOverlay} />
        </projectContext.Provider>
                <DynoBG/>
                <div className='container'>
                    <Canvas
                        className="box"
                        camera={ {
                            fov: 40,
                            near: 0.1,
                            far: 200,
                            // position: [0.1,1.1,4.5],
                            position: [0.1,1.2,4.5],
                            rotation: [0,0,0]
                        } }
                        >  
                        <World />
                    </Canvas>
                    <div className='box'><Intro trigger={setOverlay} /></div>
                </div>
                <div className={`loadingOverlay ${loadingView ? 'active' : ''}`}>
                        <img className={`loadingIndicator ${loadingView ? '': 'displayToggle'}`} src={app.imports.models.loading}/>
                </div>
    </>
}   