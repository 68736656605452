export default {
    rainy:{
        early_morning:
            {
                angle: 200,
                colors:[
                    {
                        color: '#405469',
                        location: 40
                    },
                    {
                        color:'#99ADCB',
                        location: 100
                    }
                ]
            },
        morning:
            {
                angle: 280,
                colors:[
                    {
                        color: '#405469',
                        location: 60
                    },
                    {
                        color:'#99ADCB',
                        location: 130
                    }
                ]
            },
        early_afternoon:{
            angle: 320,
            colors:[
                {
                    color: '#405469',
                    location: 40
                },
                {
                    color:'#99ADCB',
                    location: 100
                }
            ]
        },
        afternoon:{
            angle: 0,
            colors:[
                {
                    color: '#405469',
                    location: 40
                },
                {
                    color:'#99ADCB',
                    location: 140
                }
            ]
        },
        late_afternoon:{
            angle: 33,
            colors:[
                {
                    color: '#405469',
                    location: 55
                },
                {
                    color:'#99ADCB',
                    location: 120
                }
            ]
        },
        evening:{
            angle: 140,
            colors:[
                {
                    color: '#405469',
                    location: 40
                },
                {
                    color:'#99ADCB',
                    location: 160
                }
            ]
        },
        night:{
            angle: 180,
            colors:[
                {
                    color: '#302b32',
                    location: 0
                },
                {
                    color: '#28222c',
                    location: 20
                },
                {
                    color:'#47414f',
                    location: 120
                }
            ]
        }
    },
    snowy:{
        early_morning:
            {
                angle: 200,
                colors:[
                    {
                        color: '#91A2D9',
                        location: 40
                    },
                    {
                        color:'#D5DCFE',
                        location: 100
                    }
                ]
            },
        morning:
            {
                angle: 280,
                colors:[
                    {
                        color: '#91A2D9',
                        location: 60
                    },
                    {
                        color:'#D5DCFE',
                        location: 130
                    }
                ]
            },
        early_afternoon:{
            angle: 320,
            colors:[
                {
                    color: '#91A2D9',
                    location: 40
                },
                {
                    color:'#D5DCFE',
                    location: 100
                }
            ]
        },
        afternoon:{
            angle: 0,
            colors:[
                {
                    color: '#91A2D9',
                    location: 40
                },
                {
                    color:'#D5DCFE',
                    location: 140
                }
            ]
        },
        late_afternoon:{
            angle: 33,
            colors:[
                {
                    color: '#91A2D9',
                    location: 55
                },
                {
                    color:'#D5DCFE',
                    location: 120
                }
            ]
        },
        evening:{
            angle: 140,
            colors:[
                {
                    color: '#91A2D9',
                    location: 40
                },
                {
                    color:'#D5DCFE',
                    location: 160
                }
            ]
        },
        night:{
            angle: 180,
            colors:[
                {
                    color: '#302b32',
                    location: 0
                },
                {
                    color: '#28222c',
                    location: 20
                },
                {
                    color:'#47414f',
                    location: 120
                }
            ]
        }
    },
    cloudy:{
        early_morning:
            {
                angle: 200,
                colors:[
                    {
                        color: '#7D9FB8',
                        location: 50
                    },
                    {
                        color:'#C1A7E0',
                        location: 100
                    }
                ]
            },
        morning:
            {
                angle: 280,
                colors:[
                    {
                        color: '#7D9FB8',
                        location: 60
                    },
                    {
                        color:'#C1A7E0',
                        location: 130
                    }
                ]
            },
        early_afternoon:{
            angle: 320,
            colors:[
                {
                    color: '#7D9FB8',
                    location: 40
                },
                {
                    color:'#C1A7E0',
                    location: 100
                }
            ]
        },
        afternoon:{
            angle: 0,
            colors:[
                {
                    color: '#7D9FB8',
                    location: 40
                },
                {
                    color:'#C1A7E0',
                    location: 140
                }
            ]
        },
        late_afternoon:{
            angle: 33,
            colors:[
                {
                    color: '#7D9FB8',
                    location: 55
                },
                {
                    color:'#C1A7E0',
                    location: 120
                }
            ]
        },
        evening:{
            angle: 140,
            colors:[
                {
                    color: '#7D9FB8',
                    location: 40
                },
                {
                    color:'#C1A7E0',
                    location: 160
                }
            ]
        },
        night:{
            angle: 180,
            colors:[
                {
                    color: '#302b32',
                    location: 0
                },
                {
                    color: '#28222c',
                    location: 20
                },
                {
                    color:'#47414f',
                    location: 120
                }
            ]
        }
    },
    sunny:{
        early_morning:
            {
                angle: 200,
                colors:[
                    {
                        color: '#7D9FB8',
                        location: 30
                    },
                    {
                        color:'#E0A7A7',
                        location: 100
                    }
                ]
            },
        morning:
            {
                angle: 280,
                colors:[
                    {
                        color: '#7D9FB8',
                        location: 20
                    },
                    {
                        color:'#E26E5D',
                        location: 130
                    }
                ]
            },
        early_afternoon:{
            angle: 320,
            colors:[
                {
                    color: '#7AC5FF',
                    location:10
                },
                {
                    color:'#E26E5D',
                    location: 90
                },
                {
                    color:'#FF8E00',
                    location: 120
                }
            ]
        },
        afternoon:{
            angle: 0,
            colors:[
                {
                    color: '#7AC5FF',
                    location:-60
                },
                {
                    color:'#E26E5D',
                    location: 90
                },
                {
                    color:'#FF8E00',
                    location: 120
                }
            ]
        },
        late_afternoon:{
            angle: 33,
            colors:[
                {
                    color: '#7AC5FF',
                    location:0
                },
                {
                    color:'#E26E5D',
                    location: 90
                },
                {
                    color:'#FF8E00',
                    location: 120
                }
            ]
        },
        evening:{
            angle: 140,
            colors:[
                {
                    color: '#587ae2',
                    location:10
                },
                {
                    color:'#E26E5D',
                    location: 110
                },
                {
                    color:'#FF8E00',
                    location: 120
                }
            ]
        },
        night:{
            angle: 180,
            colors:[
                {
                    color: '#302b32',
                    location: 0
                },
                {
                    color: '#28222c',
                    location: 20
                },
                {
                    color:'#47414f',
                    location: 120
                }
            ]
        }
    }
}