import { useEffect, useRef } from "react"
import AppFunctions from "../AppFunctions"
import Backgrounds from '../Backgrounds'

export default function DynoBG(){
    
const app = new AppFunctions()

const backgroundDefaults = {
    position:'fixed',
    margin: '0',
    padding: '0',
    top:'0',
    left:'0',
    width:'100%',
    height: '100%'
}

let backgroundDetails = Backgrounds

let adaptiveBg = useRef(null)

const setBackground = (colors) =>{    
    let gradientArray = []
    gradientArray.push('linear-gradient(',colors.angle, 'deg,')

    for (let [index, color] of colors.colors.entries()) {
        if(index < colors.colors.length -1){
            gradientArray.push(' ' + color.color + ' ' + color.location + '%,')
        }else{
            gradientArray.push(' ' + color.color + ' ' + color.location + '%'+')');
        }
    }
    let cssGradient = gradientArray.join('')
    return cssGradient
}

useEffect(()=>{
    const loadBackground = async () =>{
        try{
            const weatherData = await app.weather.getWeatherData()
            
            const weatherFormat = weatherData?.formatWeather
            const timeOfDay = weatherData?.timeOfDay
            const backgroundDetail = backgroundDetails[weatherFormat]?.[timeOfDay]

            const background = setBackground(backgroundDetail)
            adaptiveBg.current.style.background = background 

        }catch(error){
            throw new Error ('no backgrounds found')
        }
    }
    loadBackground()
})

return <>
    <div style={backgroundDefaults} ref={adaptiveBg}/>
</>
}